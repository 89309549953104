@charset "utf-8";

// font-size
$fs-3xs: 10px;
$fs-2xs: 11px;
$fs-xs: 12px;
$fs-sm: 13px;
$fs-md: 14px;
$fs-2md: 15px;
$fs-lg: 16px;
$fs-xl: 18px;
$fs-2xl: 20px;
$fs-3xl: 22px;
$fs-4xl: 24px;
$fs-5xl: 26px;
$fs-6xl: 32px;

// line-height
$lh-small: 1.5;
$lh-medium: 1.7;
$lh-large: 1.8;
$lh-x-large: 2;

// letter-spacing
$ls-small: 0.05em;
$ls-medium: 0.08em;
$ls-large: 0.1em;

// font-weight
$fw-normal: 300;
$fw-bold: 600;
$fw-700: 700;

// font-family
$font-family-base:
  // ヒラギノ
  "Hiragino Sans",
  // ヒラギノ
  "Meiryo",
  "Yu Gothic",
  sans-serif !default; // Sans serif fallback

// font-family
$font-family-arial:
  "Arial",
  // ヒラギノ
  "Hiragino Sans",
  // ヒラギノ
  "Meiryo",
  "Yu Gothic",
  sans-serif !default; // Sans serif fallback
