@charset "utf-8";

.p-doc-cards {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 48px;
}

.p-doc-cards__text {
  font-size: $fs-2md;
  margin-bottom: 24px;
}
