@charset "utf-8";

.c-breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  a {
    color: $gray-500;
  }
}

.c-breadcrumb__item {
  display: flex;
  height: 16px;
  font-size: $fs-xs;
  list-style: none;

  &:before {
    @include icon(6px, 16px);
    margin: 0 8px;
    content: "";
    background-image: url("images/icon/arrow-right-gray.svg");
    background-position: 0 4px;
  }

  &:first-child {
    &:before {
      content: none;
    }
  }
}

.c-breadcrumb__root {
  @include icon(16px, 16px);
  margin-bottom: 2px;
  background-image: url("images/icon/home.svg");
}

.c-breadcrumb__text {
  font-weight: $fw-bold;
  color: $gray-600;
}
