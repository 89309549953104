@charset "utf-8";

.p-lp-more {
  margin: 0 24px 56px;
}

.p-lp-more__main {
  margin-bottom: 16px;
  padding: 24px;
  background-color: #ebf6f3;
  border-radius: 16px;
}

.p-lp-more__balloon {
  position: relative;
  margin: 0 auto 24px;
  padding: 8px 16px;
  background: $green-100;
  border-radius: 51px;
  font-weight: 600;
  font-size: $fs-xl;
  line-height: 33px;
  color: $white;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    border: 12px solid transparent;
    border-top: 12px solid $green-100;
  }
}

.p-lp-more__title {
  font-weight: 600;
  font-size: $fs-lg;
  line-height: 170%;
  color: $green-100;

  br {
    display: none;
  }
}

.p-lp-more__desc {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: $gray-600;
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-lp-more {
    margin: 0 24px 80px;
  }

  .p-lp-more__main {
    padding: 40px;
  }

  .p-lp-more__balloon {
    width: 426px;
    font-size: $fs-3xl;
    padding: 16px 32px;
  }

  .p-lp-more__title {
    font-size: $fs-3xl;
    text-align: center;

    br {
      display: block;
    }
  }
}

/* 1300px ~ */
@include min-screen($breakpoint-pc-large-max) {
  .p-lp-more {
    margin: 0 0 80px;
  }
}
