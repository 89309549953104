@charset "utf-8";

.p-top-drop__empty {
  width: 320px;
  padding: 24px 0;
}

.p-top-drop__empty__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.p-top-drop__empty__desc {
  margin-bottom: 16px;
  line-height: $lh-medium;
}

.p-top-drop__empty__button {
  margin: 0 auto;
  width: 160px;
}
