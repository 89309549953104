@charset "utf-8";

.p-term-term {
  p {
    font-size: $fs-md;
  }
}

.p-term-term__category {
  margin-bottom: 16px;
}

.p-term-term__content {
  line-height: $lh-medium;

  & > p + p {
    margin-top: 8px;
  }
}
