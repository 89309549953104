@charset "utf-8";

.p-facility-post {
  margin-bottom: 56px;
}

.p-facility-post__head {
  padding-bottom: 16px;
  border-bottom: 1px solid $gray-300;
  margin-bottom: 0;
}

.p-facility-post__card {
  padding: 16px 0;
  border-bottom: 1px solid $gray-300;
}

.p-facility-post__desc {
  color: $gray-500;
}

.p-facility-post__time {
  font-size: $fs-md;
  font-weight: $fw-bold;
}

.p-facility-post__text {
  font-size: $fs-2md;
  line-height: $lh-large;
}

.p-facility-post__info {
  display: flex;
  width: fit-content;
  margin-left: auto;
  gap: 8px;

  & i {
    width: 20px;
    height: 20px;
  }
}
