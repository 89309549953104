@charset "utf-8";

.c-group {
  padding: 16px 24px;
  border: 1px solid $gray-400;
  border-radius: 8px;
}

.c-group__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}
