@charset "utf-8";

.p-lp-layout {
  background-color: $white;
}

.p-lp-layout__main {
  max-width: 1180px;
  margin: 0 auto;
}

/* 768px ~ */
@include min-screen($breakpoint-tablet-max) {
  .p-lp-layout__main {
    margin: 0 24px;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-lp-layout__main {
    margin: 0 auto;
  }
}
