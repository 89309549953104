@charset "utf-8";

.p-patient-activity__date {
  margin-bottom: 4px;
  color: $gray-600;
}

.p-patient-activity__text {
  font-size: $fs-2md;
  font-weight: $fw-bold;
}
