@charset "utf-8";

.p-req-form__step {
  margin: 24px auto 16px;
}

.p-req-form__msg {
  text-align: center;
}

.p-req-form__desc {
  font-size: $fs-xs;
  color: $gray-600;
}

.p-req-form__head {
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.p-req-form__back {
  width: 160px;
}

.p-req-form__pa {
  text-align: center;
  line-height: $lh-medium;
}

.p-req-form__term {
  margin: -16px auto 0;
}
