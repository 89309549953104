.c-form-group {
  display: flex;
  width: 100%;

  &.vertical {
    flex-direction: column;
  }
}

.c-form-group__label {
  display: flex;
  min-width: 160px;
  margin-right: 20px;
  font-size: $fs-lg;
  font-weight: $fw-bold;

  &.required::after {
    display: inline;
    margin-left: 4px;
    color: $red;
    content: "*";
  }

  &.sm {
    min-width: auto;
    font-size: $fs-md;
  }
}

.c-form-group.vertical > .c-form-group__label {
  margin-right: 0;
  margin-bottom: 12px;
}

.c-form-group__hint {
  margin-top: 8px;
  font-size: $fs-sm;
  color: $gray-500;

  &.pre {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.c-form-group__main {
  flex: 1;
}

.c-form-group__main.flex {
  display: flex;
  gap: 16px;
}

.c-form-group.small > .c-form-group__label {
  min-width: 60px;
  margin-right: 8px;
  font-size: $fs-md;
}
