.c-search-empty {
  padding: 40px 0;
  font-size: $fs-3xl;
  font-weight: $fw-bold;
  color: $gray-400;
  text-align: center;
}

.c-empty {
  max-width: 800px;
  margin: 48px auto;
  text-align: center;
}

.c-empty__title {
  margin-bottom: 12px;
  font-size: $fs-2xl;
}

.c-empty__desc {
  margin-bottom: 24px;
  font-size: $fs-2md;
  font-weight: $fw-bold;
  color: $gray-500;
}

.c-empty__button {
  width: fit-content;
  padding-right: 64px;
  padding-left: 64px;
  margin: 0 auto;
}
