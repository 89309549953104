@charset "utf-8";

.p-msg-top {
  display: flex;
  justify-content: space-between;
  margin: 12px 24px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid $gray-200;
}

.p-msg-top__left {
  display: flex;
  gap: 12px;
  align-items: center;
}

.p-msg-top__line {
  display: flex;
  gap: 12px;
  align-items: center;

  & h2 {
    margin-bottom: 0;
  }
}
