@charset "utf-8";

.c-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: $fs-2md;
  font-weight: $fw-bold;
  text-align: center;
  border: none;
  border-radius: 32px;
  transition: all 0.3s;
}

.c-button:hover {
  text-decoration: none;
  transition: all 0.3s;
}

.c-button:focus {
  outline: none;
}

%c-button--primary {
  color: $white;
  background-color: $green-100;
}

.c-button--primary {
  @extend %c-button--primary;
}

.c-button--primary:visited {
  @extend %c-button--primary;
}

.c-button--primary:hover {
  background-color: color-lighten($green-100, 7%);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

%c-button--secondary {
  color: $green-100;
  background-color: $green-300;
}

.c-button--secondary {
  @extend %c-button--secondary;
}

.c-button--secondary:visited {
  @extend %c-button--secondary;
}

.c-button--secondary:hover {
  background-color: color-darken($green-200, 7%);
}

%c-button--green {
  color: $gray-700;
  background-color: $green-200;
}

.c-button--green {
  @extend %c-button--green;
}

.c-button--green:visited {
  @extend %c-button--green;
}

.c-button--green:hover {
  background-color: color-darken($green-200, 7%);
}

%c-button--white {
  color: $gray-700;
  font-weight: normal;
  background-color: $white;
  border: 2px solid $gray-300;
  border-radius: 4px;
}

.c-button--white {
  @extend %c-button--white;
}

.c-button--white:visited {
  @extend %c-button--white;
}

.c-button--white:hover {
  background-color: color-darken($white, 7%);
}

%c-button--black {
  color: $white;
  background-color: $gray-700;
}

.c-button--black {
  @extend %c-button--black;
}

.c-button--black:visited {
  @extend %c-button--black;
}

.c-button--black:hover {
  background-color: color-lighten($gray-700, 7%);
}

%c-button--gray {
  color: $gray-700;
  background-color: $gray-200;
}

.c-button--gray {
  @extend %c-button--gray;
}

.c-button--gray:visited {
  @extend %c-button--gray;
}

.c-button--gray:hover {
  background-color: color-darken($gray-200, 7%);
}

%c-button--msg {
  color: $green-100;
  background-color: $white;
  border-radius: 34px;
  display: inline-block;
}

.c-button--msg {
  @extend %c-button--msg;
}

.c-button--msg:visited {
  @extend %c-button--msg;
}

.c-button--msg:hover {
  background-color: color-darken($white, 7%);
}

%c-button--disabled {
  color: $white;
  pointer-events: none;
  background-color: $gray-400;
}

.c-button.disabled {
  @extend %c-button--disabled;

  &:visited {
    @extend %c-button--disabled;
  }
}

.c-button--small {
  padding: 8px 16px;
  font-size: $fs-sm;
  border-radius: 4px;
}

.c-button--large {
  padding: 10px 16px;
  font-size: $fs-lg;
}

.c-button--xl {
  padding: 10px 32px;
  font-size: $fs-lg;
}

.c-button__icon {
  margin-right: 8px;
}
