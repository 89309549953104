@charset "utf-8";

.p-req-msg {
  flex: 1;
  height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
}

.p-req-msg__bread {
  padding: 24px 24px 0 24px;
}

.p-req-msg__head {
  padding: 0 24px;
}

.p-req-msg__msg {
  flex: 1;
  overflow-y: scroll;
  border-bottom: 1px solid $gray-300;
  padding: 0 24px;
  @include scrollbar-none;
}

.p-req-msg__footer {
  padding: 16px 24px;
}
