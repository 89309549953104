@charset "utf-8";

$loader-zindex: 2000;

$side-slide-zindex: 100;

$modal-frame-zindex: 1040;
$modal-zindex: 1041;
$modal-over2-zindex: 1050;

$dropdown-menu-zindex: 1050;

$slide-frame-zindex: 1060;
$slide-zindex: 1061;

$notification-zindex: 1070;
