.c-form-radio__mimic {
  display: block;
  width: 21px;
  height: 21px;
  margin-right: 8px;
  background-image: url("images/icon/radio-off.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.c-form-radio {
  display: flex;
  align-items: center;
  font-size: $fs-2md;
  cursor: pointer;

  input[type="radio"] {
    display: none;
    appearance: none;
  }

  input[type="radio"]:checked + &__mimic {
    background-image: url("images/icon/radio-on.svg");
  }
}

.c-form-radios {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &.col2 {
    @include form-grid(2);
  }

  &.col3 {
    @include form-grid(3);
  }

  &.col4 {
    @include form-grid(4);
  }

  &.col5 {
    @include form-grid(5);
  }
}
