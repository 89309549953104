@charset "utf-8";

.p-map-detail {
  min-width: 1080px;
}

.p-map-detail__group {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid $gray-400;
}

.p-map-detail__group__head {
  margin-bottom: 16px;
  font-size: $fs-3xl;
  font-weight: $fw-bold;
}

.p-map-detail__group__main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.p-map-detail__title {
  margin-bottom: 12px;
  font-size: $fs-xl;
  font-weight: $fw-bold;
}

.p-map-detail__desc {
  margin-bottom: 16px;
  font-size: $fs-2md;
}

.p-map-detail__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.p-map-detail__tag {
  width: calc((100% - 24px) / 4);
}

.p-map-detail__cost {
  width: 200px;
}

.p-map-detail__top {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $gray-300;
}
