@charset "utf-8";

.p-lp-pr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 24px 48px;
}

.p-lp-pr__img--pc {
  display: none;
}

.p-lp-pr__img--sp {
  width: 100%;
  margin: 0 auto 24px;

  img {
    width: 100%;
  }
}

.p-lp-pr__title {
  margin-bottom: 12px;
  font-weight: $fw-bold;
  font-size: $fs-2xl;
  line-height: 180%;
  text-align: center;

  br {
    display: none;
  }
}

.p-lp-pr__desc {
  font-size: $fs-2md;
  line-height: 200%;

  br {
    display: none;
  }
}

/* 768pxpx ~ */
@include min-screen($breakpoint-tablet-max) {
  .p-lp-pr__img--pc {
    display: block;
    width: 95%;
    margin: 0 auto 40px;

    img {
      width: 100%;
    }
  }

  .p-lp-pr__img--sp {
    display: none;
  }
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-lp-pr {
    margin: 0 24px 120px;
  }

  .p-lp-pr__img--pc {
    width: 80%;
  }

  .p-lp-pr__title {
    font-size: $fs-6xl;
    margin-bottom: 24px;

    br {
      display: block;
    }
  }

  .p-lp-pr__desc {
    font-size: $fs-xl;
    text-align: center;

    br {
      display: block;
    }
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-lp-pr {
    margin: 0 0 120px;
  }
}
