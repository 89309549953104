@charset "utf-8";

.p-patient-memo {
  flex: 1;
}

.p-patient-memo__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.p-patient-memo__pin {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  font-weight: $fw-bold;
}

.p-patient-memo__title {
  margin-bottom: 8px;
  font-size: $fs-sm;
  color: $gray-600;
}

.p-patient-memo__body {
  line-height: $lh-large;
}

.p-patient-memo__card {
  position: relative;
}

.p-patient-memo__drop {
  position: absolute;
  top: 16px;
  right: 8px;
}

.p-patient-memo__new {
  padding: 16px;
}

.p-patient-memo__new__title {
  font-weight: $fw-bold;
  font-size: $fs-lg;
  margin-bottom: 4px;

  &::before {
    @include icon(17px, 17px);
    content: "";
    margin-right: 8px;
    background-image: url("images/icon/social.svg");
  }
}

.p-patient-memo__new__text {
  line-height: $lh-large;
}
