@charset "utf-8";

.p-map-list-menu {
  width: 320px;
  padding: 24px;
  box-shadow: 0 0 24px rgba(141, 141, 141, 0.25);
  border-radius: 12px;

  &.large {
    width: 380px;
  }
}

.p-map-list-menu__item {
  display: flex;
  font-size: $fs-2md;
}

.p-map-list-menu__item + .p-map-list-menu__item {
  margin-top: 8px;
}

.p-map-list-menu__button {
  width: 105px;
  margin-top: 8px;
  margin-left: auto;
}

.p-map-list-menu__empty__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.p-map-list-menu__empty__desc {
  margin-bottom: 16px;
}

.p-map-list-menu__buttons {
  display: flex;
  gap: 12px;
}
