@charset "utf-8";

.p-facility-form__save {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.p-facility-form__address {
  width: 70px;
}

.p-facility-form__desc {
  text-align: center;
  margin-bottom: 16px;
  margin-top: 40px;
  font-size: $fs-2md;
}

.p-facility-form__h2 {
  font-size: $fs-xl;
  font-weight: $fw-bold;
  text-align: center;
}

.p-facility-form__map {
  margin-top: 12px;
  height: 280px;
  width: 100%;
}
