@charset "utf-8";

.p-room-modal__main {
  padding-bottom: 48px;
}

.p-room-modal__desc {
  margin-bottom: 16px;
  font-size: $fs-2md;
}
