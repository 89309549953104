.c-docs-card__img {
  width: 100%;
  height: 300px;
  margin-bottom: 32px;
  border-radius: 8px;
  box-shadow: 8px 8px 0 0 $green-200;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.c-docs-card__title {
  margin-bottom: 4px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.c-docs-card__desc {
  margin-bottom: 16px;
  color: $gray-500;
}

.c-docs-card__button {
  width: 260px;
  margin: 0 auto;
}
