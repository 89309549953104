@charset "utf-8";

.c-pagination {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

%c-pagination__icon {
  display: block;
  width: 20px;
  height: 39px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 8px;
}

%c-pagination__disable {
  pointer-events: none;
}

.c-pagination__next > a {
  @extend %c-pagination__icon;
  background-image: url("images/icon/next.svg");
}

.c-pagination__prev {
  & > a {
    @extend %c-pagination__icon;
    background-image: url("images/icon/prev.svg");
  }

  &.disabled > a {
    @extend %c-pagination__disable;
    background-image: url("images/icon/prev-off.svg");
  }
}

.c-pagination__next {
  &.disabled > a {
    @extend %c-pagination__disable;
    background-image: url("images/icon/next-off.svg");
  }
}

.c-pagination__num {
  padding: 8px 15px;
  font-size: $fs-2md;
  font-weight: $fw-bold;
  color: $gray-700;

  &:visited {
    color: $gray-700;
  }

  &.selected {
    color: $white;
    background-color: $gray-700;
    border-radius: 4px;

    & a {
      color: $white;
      background-color: transparent;
    }
  }
}
