@charset "utf-8";

.p-patient-status {
  display: flex;
  align-items: center;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid $gray-400;
  border-radius: 4px;
}

.p-patient-status__status {
  width: 100px;
  margin-right: 24px;
}

.p-patient-status__calendar {
  @include icon(22px, 22px);
  margin-right: 12px;
  background-image: url("images/icon/calendar.svg");
}

.p-patient-status__text {
  font-weight: $fw-bold;
}

.p-patient-status__desc {
  margin-left: 16px;
  font-size: $fs-sm;
  color: $gray-500;
}

.p-patient-status__right {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: $fw-bold;
}

.p-patient-status__edit {
  @include icon(17px, 17px);
  margin-right: 8px;
  background-image: url("images/icon/edit.svg");
}

.p-patient-status__form {
  & .c-form-group__label {
    min-width: auto;
    width: 100px;
  }
}
