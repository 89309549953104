@charset "utf-8";

.c-notification {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $notification-zindex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
  padding: 0 16px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.c-notification--notice {
  color: $white;
  background-color: $green-100;
}

.c-notification--alert {
  color: $white;
  background-color: $red;
}
