@charset "utf-8";

.p-login-news {
  border: 1px solid $green-100;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  width: 450px;
  background-color: $green-200;

  a {
    background-color: transparent;
  }
}

.p-login-news__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.p-login-news__text {
  font-size: $fs-2md;
  font-weight: $fw-bold;
}

.p-login-news__close {
  width: 12px;
  height: 12px;
  cursor: pointer;
  background: url("images/icon/close.svg") no-repeat center;
  background-size: cover;
}

/* sp */
@include max-screen($breakpoint-tablet-max) {
  .p-login-news {
    width: auto;
  }

  .p-login-news__inner {
    gap: 12px;
  }
}
