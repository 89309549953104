@charset "utf-8";

.p-map-list-detail__top {
  display: flex;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.p-map-list-detail__anchor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 59px;
  border-right: 1px solid $gray-300;
  cursor: pointer;
}

.p-map-list-detail__top__main {
  padding: 16px 24px;
}

.p-map-list-detail__title {
  font-size: $fs-xl;
  font-weight: $fw-bold;
}

.p-map-list-detail__dropdown {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-left: auto;
}

.p-map-list-detail__menu {
  width: 150px;
  right: -10px;
}
