@charset "utf-8";

.p-room-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.p-room-head__title {
  margin-right: 16px;
  margin-bottom: 0;
}

.p-room-head__list {
  min-width: 200px;
}

.p-room-head__right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.p-room-head__search {
  margin-right: 16px;
  position: relative;
}
