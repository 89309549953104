@charset "utf-8";

.p-lp-faq {
  background-color: #ebf6f3;
  padding: 56px 24px;
}

.p-lp-faq__inner {
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.p-lp-faq__head {
  margin-bottom: 32px;
  font-weight: $fw-bold;
  font-size: $fs-4xl;
  text-align: center;
}

.p-lp-faq__card {
  padding: 24px;
  background: $white;
  border-radius: 24px;
}

.p-lp-faq__title {
  position: relative;
  margin-bottom: 8px;
  font-weight: $fw-bold;
  font-size: $fs-lg;
  padding-left: 24px;

  &:before {
    content: "Q";
    left: 0;
    position: absolute;
    font-weight: $fw-bold;
    font-size: $fs-lg;
    color: $green-100;
  }
}

.p-lp-faq__desc {
  font-size: $fs-md;
  padding-left: 24px;
}

/* 768px ~ */
@include min-screen($breakpoint-tablet-max) {
  .p-lp-faq {
    padding: 56px 48px;
  }
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-lp-faq {
    padding: 120px 24px;
  }

  .p-lp-faq__head {
    margin-bottom: 60px;
    font-size: 32px;
  }

  .p-lp-faq__inner {
    gap: 24px;
  }

  .p-lp-faq__card {
    padding: 32px;
  }

  .p-lp-faq__title {
    font-size: $fs-xl;

    &:before {
      font-size: $fs-xl;
    }
  }

  .p-lp-faq__desc {
    font-size: $fs-2md;
  }
}

/* 1300px ~ */
@include min-screen($breakpoint-pc-large-max) {
  .p-lp-faq {
    padding: 120px 0;
  }
}
