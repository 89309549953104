.c-dialog__footer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 24px 0;
}

.c-dialog__button {
  width: 150px;
}
