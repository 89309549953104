@charset "utf-8";

.p-facility-data {
  margin-bottom: 56px;
}

.p-facility-data__labels {
  margin-bottom: 12px;
}

.p-facility-data__h1 {
  margin-bottom: 12px;
}

.p-facility-data__heading {
  font-weight: $fw-bold;
  margin-bottom: 8px;
}

.p-facility-data__table {
  border-top: 1px solid $gray-300;
  font-size: $fs-2md;
  margin-bottom: 16px;
  width: 100%;
  table-layout: fixed;

  & th {
    width: 120px;
    font-weight: $fw-bold;
    vertical-align: top;
  }

  & th,
  td {
    padding: 16px 0;
    border-bottom: 1px solid $gray-300;
    line-height: $lh-medium;
  }

  a {
    color: $green-100;

    &::after {
      content: url("images/icon/link-blank-green.svg");
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-left: 8px;
    }
  }
}

.p-facility-data__desc {
  font-size: $fs-sm;
  color: $gray-500;
}

.p-facility-data__block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.p-facility-data__address {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.p-facility-data__strong {
  font-weight: $fw-bold;
}

.p-facility-data__map {
  position: relative;
  border-radius: 8px;
  min-width: 220px;
  overflow: hidden;
  height: 160px;

  iframe {
    height: 100%;
  }
}

.p-facility-data__title {
  font-size: $fs-2md;
  font-weight: $fw-bold;
}

.p-facility-data__select {
  max-width: 300px;
}

.p-facility-data__treatments + .p-facility-data__treatments {
  margin-top: 12px;
}

.p-facility-data__staff__table {
  border: 1px solid $gray-400;
  width: 100%;
  margin-bottom: 8px;

  thead {
    background-color: $gray-100;
  }

  th {
    padding: 8px 16px;

    &:not(:last-child) {
      border-right: 1px solid $gray-400;
    }
  }

  tbody {
    th {
      font-weight: $fw-normal;
    }
  }
}

.p-facility-data__menu {
  width: 200px;
}
