@charset "utf-8";

.p-map-top {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 0 1px 2px rgba(189, 189, 189, 0.25);
}

.p-map-top__search {
  width: 220px;
  margin-right: 24px;
}

.p-map-top__types {
  display: flex;
  gap: 18px;
  margin-right: 16px;
}

.p-map-top__select {
  margin-right: 12px;
}

.p-map-top__right {
  margin-left: auto;
}

.p-map-top__print {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.p-map-top__detail {
  position: relative;
}

.p-map-top__badge {
  position: absolute;
  right: -14px;
  top: -10px;
}

.p-map-top__clear {
  margin-left: 16px;
  font-weight: normal;
  cursor: pointer;
  font-size: $fs-sm;
}
