@charset "utf-8";

.p-map-im__title {
  font-size: $fs-lg;
  font-weight: $fw-bold;
  margin-bottom: 8px;
}

.p-map-im__body {
  padding-bottom: 40px;
}
