@charset "utf-8";

.p-msg-pt {
  width: 100%;
  table-layout: fixed;
  margin: 8px 0;

  & th,
  td {
    padding: 8px 0;
    vertical-align: top;
  }

  & td {
    padding-right: 12px;
    padding-left: 12px;
  }

  & th {
    padding-left: 24px;
    font-weight: $fw-bold;
    width: 100px;
  }
}
