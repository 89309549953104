@charset "utf-8";

.p-msg-msg {
  padding: 12px 24px;
  font-weight: $fw-normal;

  &.active,
  &:hover {
    background-color: $green-200;
  }
}

.p-msg-msg__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 4px;

  p {
    font-weight: $fw-bold;
  }
}

.p-msg-msg__time {
  color: $gray-500;
  font-size: $fs-xs;
  font-weight: normal;
}

.p-msg-msg__text {
  font-size: $fs-xs;
  line-height: $lh-medium;
  color: $gray-500;
}

.p-msg-msg.new {
  font-weight: $fw-bold;

  & .p-msg-msg__time {
    color: $gray-700;
  }

  & .p-msg-msg__text {
    color: $gray-700;
  }
}
