@charset "utf-8";

.p-req-empty__button {
  margin: 0 auto 16px;
  width: 300px;
}

.p-req-empty__desc {
  margin-bottom: 16px;
}
