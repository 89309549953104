@charset "utf-8";

.u-mb-4 {
  margin-bottom: 4px;
}

.u-mb-8 {
  margin-bottom: 8px;
}

.u-mb-12 {
  margin-bottom: 12px;
}

.u-mb-16 {
  margin-bottom: 16px;
}

.u-mb-32 {
  margin-bottom: 32px;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mt-8 {
  margin-top: 8px;
}

.u-mt-12 {
  margin-top: 12px;
}

.u-mt-16 {
  margin-top: 16px;
}

.u-mt-32 {
  margin-top: 32px;
}

.u-mr-4 {
  margin-right: 4px;
}

.u-mr-8 {
  margin-right: 8px;
}

.u-mr-12 {
  margin-right: 12px;
}

.u-mr-16 {
  margin-right: 16px;
}
