.c-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $loader-zindex;
  background-color: rgba(255, 255, 255, 0.95);
  backface-visibility: hidden;
}

.c-loader__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
