@charset "utf-8";

$widths: 100 120 150 220;

@each $width in $widths {
  .u-w-#{$width} {
    width: #{$width}px;
  }
}

.u-w-full {
  width: 100%;
}
