@charset "utf-8";

.p-offer-pa__day {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: $fw-bold;
}

.p-offer-pa__cal {
  @include icon(22px, 22px);
  background-image: url("images/icon/calendar.svg");
}
