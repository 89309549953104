@charset "utf-8";

.p-top-search {
  display: flex;
  margin-bottom: 20px;
}

.p-top-search__box {
  margin-right: 32px;
  width: 300px;

  input[type="text"]:focus {
    border: none;
    box-shadow: none;
  }
}

.p-top-search__box__button {
  margin: 4px;
  width: 80px;
}

.p-top-search__new {
  display: flex;
  gap: 8px;
  margin-left: auto;
}
