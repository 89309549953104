@charset "utf-8";

.p-patient-adl__main {
  margin-top: 16px;
}

.p-patient-adl__group {
  & .c-form-group {
    display: block;
  }

  & .c-form-group__label {
    font-size: $fs-2md;
    margin-bottom: 8px;
  }
}

.p-patient-adl__group__label {
  font-size: $fs-md;
  font-weight: $fw-bold;
}

.p-patient-adl__label {
  min-width: 100px;
  font-size: $fs-md;
}
