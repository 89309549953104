/* reboot.css https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_reboot.scss */

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

// scss-docs-start reboot-body-rules
body {
  margin: 0; // 1
  font-family: $font-family-base;
  font-size: $fs-md;
  font-weight: $fw-normal;
  line-height: $lh-small;
  color: $gray-700;
  text-align: left;
  background-color: $white; // 2
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba($black, 0); // 4
  @include scrollbar-none;
}

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: $fw-bold;
}

h1 {
  font-size: $fs-5xl;
}

h2 {
  font-size: $fs-3xl;
}

h3 {
  font-size: $fs-lg;
}

/* Grouping content */
p,
blockquote,
dl,
dd,
figure {
  margin: 0;
}

pre {
  display: block;
  overflow: auto;
  margin: 0;
  font-family: monospace, sans-serif;
  word-wrap: break-word;
  white-space: pre-wrap;
}

ol,
ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a:hover,
a:active {
  outline: 0;
}

strong,
b {
  font-weight: $fw-bold;
}

small {
  font-size: $fs-xs;
}

q {
  quotes: none;
}

em {
  /*bringing italics back to the em element*/
  font-style: italic;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding: 0;
  /*coordinated marking to match cell's padding*/
  margin-bottom: 0.5em;
  /*centered so it doesn't blend in to other content*/
  text-align: center;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

/* Forms */
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

iframe {
  border: 0;
}

turbo-frame {
  display: block;
}

[hidden] {
  display: none !important;
}
