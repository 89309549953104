@charset "utf-8";

.p-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.p-login__logo {
  margin-bottom: 48px;
  width: 320px;
}

.p-login__text {
  font-size: $fs-2md;
  margin-bottom: 32px;
}

.p-login__note {
  width: 450px;
  border-radius: 8px;
  border: solid 1px $green-100;
  padding: 16px;
  margin: 0 auto 40px;
}

/* sp */
@include max-screen($breakpoint-tablet-max) {
  .p-login {
    padding: 24px;
  }

  .p-login__note {
    width: auto;
    margin: 0 0 24px;
  }
}
