.c-nav-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-400;
}

.c-nav-header__nav {
  width: 78px;
  height: 100%;
  background-image: url("images/icon/arrow-left.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  border-right: 1px solid $gray-400;
}

.c-nav-header__close {
  width: 78px;
  height: 100%;
  background-image: url("images/icon/close-black.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  border-right: 1px solid $gray-400;
}

.c-nav-header__inner {
  width: 100%;
  padding-right: 32px;
}

.c-nav-header__main {
  padding-left: 32px;
  font-size: $fs-2xl;
  font-weight: $fw-bold;
}

.c-nav-header__freedial {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  font-size: $fs-xs;
  font-weight: $fw-bold;
  color: $gray-500;

  span {
    font-size: $fs-4xl;
    font-weight: $fw-bold;
    color: $gray-700;
  }
}

.c-nav-header__freedial__text {
  font-size: $fs-sm;
}
