@charset "utf-8";

.p-user-complete {
  width: 700px;
  margin: 0 auto;
  text-align: center;
}

.p-user-complete__desc {
  font-size: $fs-md;
  line-height: $lh-medium;
}

.p-user-complete__info {
  padding: 24px 32px;
  background: $gray-100;
  border-radius: 8px;
  font-weight: $fw-bold;
  font-size: $fs-2md;
  line-height: $lh-large;
  text-align: left;
}

.p-user-complete__step {
  font-weight: normal;
}

.p-user-complete__warn {
  font-size: $fs-sm;
}

.p-user-complete__subtitle {
  font-weight: $fw-bold;
  font-size: $fs-lg;
}

.p-user-complete__link {
  background-color: transparent;
}
