@charset "utf-8";

.c-msg__day + .c-msg__day {
  margin-top: 32px;
}

.c-msg__date {
  width: fit-content;
  padding: 6px 16px;
  margin: 0 auto 24px;
  background-color: $gray-100;
  border-radius: 32px;
}

.c-msg__mark {
  @include icon(12px, 12px);
  margin-bottom: 4px;
  background-image: url("images/icon/mark.svg");
}

.c-msg__talk,
.c-msg__talk--auto {
  max-width: 600px;
  padding: 16px 24px;
  font-size: $fs-2md;
  line-height: $lh-medium;
  word-break: break-all;
  border-radius: 12px;
}

.c-msg__block__inner {
  span {
    font-size: $fs-sm;
  }

  &.right {
    align-items: flex-end;
    justify-content: flex-end;
  }

  &.left {
    align-items: flex-end;

    span {
      order: 2;
    }

    .c-msg__mark {
      order: 3;
    }

    .c-msg__talk {
      order: 1;
    }
  }
}

.c-msg__block {
  margin-bottom: 12px;
}

.c-msg__block.left .c-msg__talk {
  color: $gray-700;
  background: $green-200;
}

.c-msg__block.right .c-msg__talk--auto {
  color: $gray-700;
  background: $gray-100;
}

.c-msg__block--right {
  text-align: right;
}

.c-msg__block.right .c-msg__talk {
  color: $white;
  background: $green-100;
}

.c-msg__block.right .c-msg__files {
  display: inline-block;
  text-align: right;
}

.c-msg__file {
  display: inline-block;
  padding: 12px 16px;
  color: $green-100;
  border: solid 1px $gray-400;
  border-radius: 8px;

  &:visited {
    color: $green-100;
  }

  &:active {
    color: $green-100;
  }
}

.c-msg__file + .c-msg__file {
  margin-top: 8px;
}

.c-msg__button {
  margin-top: 8px;
}
