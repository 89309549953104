@charset "utf-8";

.p-msg-main {
  display: flex;
  height: calc(100vh - #{$header-height});
}

.p-msg-main__side {
  width: 340px;
  border-right: 1px solid $gray-400;
  display: flex;
  flex-direction: column;
}

.p-msg-main__main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.p-msg-main__inner {
  flex: 1;
  padding: 24px;
  border-bottom: 1px solid $gray-400;
  overflow-y: scroll;
}
