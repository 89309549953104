@charset "utf-8";

.c-search {
  position: relative;
  display: flex;
  align-items: center;
  background-color: $gray-100;
  border-radius: 28px;

  &::before {
    position: absolute;
    left: 16px;
    width: 16px;
    height: 18px;
    margin-right: 8px;
    content: "";
    background-image: url("images/icon/search-gray.svg");
  }

  input[type="text"] {
    width: 100%;
    padding: 8px 8px 8px 40px;
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 28px;
    outline: none;

    &::placeholder {
      color: $gray-500;
    }

    &:focus {
      border: $green-100 1px solid;
      box-shadow: 0 0 4px rgba(54, 166, 132, 0.25);
    }

    &:focus::placeholder {
      color: transparent;
    }
  }
}
