@charset "utf-8";

.p-req-group {
  padding: 24px;
  margin-top: 12px;
  border: 1px solid $gray-400;
  border-radius: 12px;
}

.p-req-group__head {
  font-size: $fs-xl;
}

.p-req-group__action {
  margin-top: 24px;
}
