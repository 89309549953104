@charset "utf-8";

.p-request-intro {
  display: flex;
  align-items: center;
  max-width: 1080px;
  width: calc(100% - 80px);
  margin: 0 auto 24px;
  padding: 24px 32px;
  border-radius: 12px;
  background: linear-gradient(45deg, $green-100, $green);
  color: $white;
}

.p-request-intro__title {
  font-weight: $fw-bold;
  font-size: $fs-3xl;
  flex-basis: 35%;
}

.p-request-intro__text {
  flex-basis: 65%;
  font-size: $fs-md;
  line-height: $lh-large;
}
