@charset "utf-8";

.p-top-navs {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
}

$navItem: search, contact, bed, offer;

.p-top-nav {
  padding: 24px;
  width: 320px;
  color: $gray-700;
  background-color: $green-200;
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 12px;

  @each $nav in $navItem {
    &--#{$nav} {
      background-image: url("images/icon/nav-bg-#{$nav}.svg");
    }
  }
}

.p-top-nav__head {
  margin-bottom: 8px;
  font-size: $fs-2xl;
  font-weight: $fw-bold;
  background-image: url("images/icon/arrow-right-green.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 16px 16px;
}

.p-top-nav__text {
  margin-bottom: 12px;
  font-size: $fs-sm;
  line-height: $lh-medium;
  color: $gray-600;
}

%p-top-nav__icon {
  width: 100%;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) 50%;
}

.p-top-nav__search {
  @extend %p-top-nav__icon;
  height: 50px;
  background-image: url("images/icon/search-green.svg");
  background-size: 50px 50px;
}

.p-top-nav__patient {
  @extend %p-top-nav__icon;
  height: 54px;
  background-image: url("images/icon/request-green.svg");
  background-size: 54px 54px;
}

.p-top-nav__bed {
  @extend %p-top-nav__icon;
  height: 52px;
  background-image: url("images/icon/bed-green.svg");
  background-size: 67px 52px;
}

.p-top-nav__offer {
  @extend %p-top-nav__icon;
  height: 56px;
  background-image: url("images/icon/offer-green.svg");
  background-size: 56px 56px;
}
