@charset "utf-8";

.p-room-table__name-col {
  max-width: 300px;
}

.p-room-table__type-col {
  max-width: 240px;
}

.p-room-row__address {
  margin-top: 4px;
  font-size: $fs-sm;
  color: $gray-600;
}

.p-room-row__bed {
  gap: 8px;
  font-size: $fs-md;
  padding: 8px 12px;
}

.p-room-row__time {
  margin-top: 8px;
  font-size: $fs-xs;
  color: $gray-600;
}

.p-room-row__title {
  margin-right: 4px;
  font-weight: $fw-bold;
}

.p-room-row__tags {
  max-width: 250px;
}

.p-room-row__tel {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  font-weight: $fw-bold;
  font-size: $fs-3xl;

  & i {
    width: 20px;
    height: 20px;
  }
}

.p-room-row__chat {
  width: 220px;
}

.p-room-row__more {
  font-weight: $fw-normal;
  font-size: $fs-sm;
}
