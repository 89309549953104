@charset "utf-8";

.c-side-nav {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-right: 1px solid $gray-400;
}

.c-side-nav__nav {
  display: flex;
  align-items: center;
  width: 210px;
  padding: 12px 16px;
  font-size: $fs-2md;
  font-weight: $fw-bold;
}

%c-side-nav__icon {
  display: block;
  width: 32px;
  margin-right: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.c-side-nav__patient {
  @extend %c-side-nav__icon;
  height: 24px;
  background-image: url("images/icon/person.svg");
}

.c-side-nav__percuss {
  @extend %c-side-nav__icon;
  height: 20px;
  background-image: url("images/icon/percuss.svg");
}

.c-side-nav__request {
  @extend %c-side-nav__icon;
  height: 28px;
  background-image: url("images/icon/request.svg");
}

.c-side-nav__nav.current,
.c-side-nav__nav:hover {
  color: $green-100;
  background-color: $green-200;
  border-radius: 4px;

  & .c-side-nav__patient {
    background-image: url("images/icon/person-green.svg");
  }

  & .c-side-nav__percuss {
    background-image: url("images/icon/percuss-green.svg");
  }

  & .c-side-nav__request {
    background-image: url("images/icon/request-green.svg");
  }
}

.c-side-nav__badge {
  margin-left: auto;
}
