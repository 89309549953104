@charset "utf-8";

.c-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 1600px;
  padding: 16px 40px;
  margin: 16px auto 0;
  border-top: 1px solid $gray-300;
}

.c-footer__link {
  padding: 0 8px;
  font-size: $fs-3xs;
}

.c-footer__link + .c-footer__link {
  border-left: 1px solid $gray-300;
}

.c-footer__brand {
  padding: 8px 0;
  margin-left: 16px;
  font-size: $fs-3xs;
  color: $gray-500;
}
