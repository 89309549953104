@charset "utf-8";

.p-facility-guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 32px;
  border: 1px solid $gray-400;
  border-radius: 8px;
}

.p-facility-guide__img {
  width: 100px;
  height: 78px;
  margin-bottom: 24px;
  background-image: url("images/bud.jpg");
}

.p-facility-guide__title {
  font-size: $fs-lg;
  font-weight: $fw-bold;
  margin-bottom: 8px;
}

.p-facility-guide__text {
  margin-bottom: 16px;
  line-height: $lh-medium;
}

.p-facility-guide__cmd {
  width: 300px;
}
