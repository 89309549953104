@charset "utf-8";

.p-map-radius {
  position: absolute;
  left: 16px;
  bottom: 16px;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: $white;
}

.p-map-radius__input {
  font-size: $fs-sm;
  padding: 6px 12px;
  width: 64px;
}
