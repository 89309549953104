@charset "utf-8";

.p-news-list {
  border-top: 1px solid $gray-300;
}

.p-news-list__item {
  font-size: $fs-2md;
  padding: 12px 12px 12px 24px;
  border-bottom: 1px solid $gray-300;
}

.p-news-list__title {
  margin-right: 12px;
}

.p-news-list__link {
}
