@charset "utf-8";

.p-map-main {
  display: flex;
  position: relative;
}

.p-map-main__map {
  flex: 1;
}
