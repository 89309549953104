@charset "utf-8";

.p-facility-table__name {
  font-weight: $fw-bold;
  color: $green-100;
}

.p-facility-table__memo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;

  & i {
    margin-left: 16px;
  }
}

.p-facility-table__fac {
  width: 150px;
}
