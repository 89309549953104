@charset "utf-8";

.p-lp-footer__main {
  max-width: 1180px;
  margin: 0 auto;
  padding: 56px 24px 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $gray-300;
  align-items: baseline;
  gap: 24px;
}

.p-lp-footer__logo {
  width: 270px;
}

.p-lp-footer__block {
  margin-right: 86px;
}

.p-lp-footer__title {
  font-weight: $fw-bold;
  font-size: $fs-md;
  margin-bottom: 12px;
}

.p-lp-footer__links {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-wrap: wrap;
  column-gap: 40px;
  font-size: $fs-sm;

  & a {
    color: $gray-600;
  }
}

.p-lp-footer__bottom {
  padding: 24px 0;
  text-align: center;
  color: $gray-500;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-lp-footer__main {
    flex-direction: row;
    padding: 120px 24px 64px;
  }

  .p-lp-footer__logo {
    width: 300px;
    margin-right: 80px;
  }

  .p-lp-footer__title {
    font-size: $fs-2md;
  }
}

/* 1300px ~ */
@include min-screen($breakpoint-pc-large-max) {
  .p-lp-footer__main {
    padding: 120px 0 64px;
  }
}
