.c-form-field {
  width: 100%;
  padding: 10px 16px;
  appearance: none;
  background-color: $white;
  border: $gray-400 solid 1px;
  border-radius: 4px;
  outline: none;
  transition: all;

  &::placeholder {
    color: $gray-500;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:focus {
    border: $green-100 1px solid;
    box-shadow: 0 0 4px rgba(54, 166, 132, 0.25);
  }

  &.invalid {
    background-color: rgba(255, 89, 89, 0.05);
    border: 1px solid $red;
    box-shadow: 0 0 4px rgba(255, 89, 89, 0.25);
  }

  &.invalid:focus {
    background-color: rgba(255, 89, 89, 0.05);
    border: 1px solid $red;
    box-shadow: 0 0 4px rgba(255, 89, 89, 0.25);
  }

  &:disabled {
    background-color: $gray-300;
  }

  &.select {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 28px 12px 12px;
    font-size: $fs-2md;
    font-weight: $fw-bold;
    appearance: none;
    cursor: pointer;
    background-color: $white;
    background-image: url("images/icon/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 50%;
    background-size: 10px 10px;
    border: $gray-400 solid 1px;
    border-radius: 4px;
    outline: none;
    transition: all;
  }

  &.select.invalid {
    background-color: rgba(255, 89, 89, 0.05);
    border: 1px solid $red;
    box-shadow: 0 0 4px rgba(255, 89, 89, 0.25);
  }

  &.inline {
    flex: 1;
  }
}
