@charset "utf-8";

.p-map-field {
  display: flex;
  align-items: center;
}

.p-map-field__title {
  font-size: $fs-lg;
  font-weight: $fw-bold;
  width: 150px;
}

.p-map-field__main {
  flex: 1;
}
