@charset "utf-8";

.p-news-detail__date {
  margin-bottom: 24px;
}

.p-news-detail__body {
  font-size: $fs-lg;
  line-height: $lh-large;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.p-news-detail__box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.p-news-detail__heading {
  font-size: $fs-4xl;
  font-weight: 600;
}

.p-news-detail__subheading {
  background-color: $gray-200;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: $fs-2xl;
  font-weight: 600;
}

.p-news-detail__list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.p-news-detail__image {
  width: 100%;
  max-width: 650px;

  img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 24px $gray-300;
  }
}
