@charset "utf-8";

.p-lp-flow {
  margin: 0 24px 32px;
}

.p-lp-flow__head {
  font-weight: 600;
  font-size: $fs-4xl;
  margin-bottom: 32px;
  text-align: center;
}

.p-lp-flow__inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.p-lp-flow__card {
  padding: 32px;
  flex: 1;
  box-shadow: 0 0 24px rgba(207, 207, 207, 0.25);
  border-radius: 24px;
}

.p-lp-flow__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 8px;
}

.p-lp-flow__desc {
  font-size: $fs-2md;
  line-height: 180%;
}

%p-lp-flow__img {
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto 32px;
}

.p-lp-flow__img1 {
  @extend %p-lp-flow__img;
  background-image: url("images/lp/flow1.png");
  width: 112px;
  height: 132px;
}

.p-lp-flow__img2 {
  @extend %p-lp-flow__img;
  background-image: url("images/lp/flow2.png");
  width: 207px;
  height: 119px;
}

.p-lp-flow__img3 {
  @extend %p-lp-flow__img;
  background-image: url("images/lp/flow3.png");
  width: 140px;
  height: 122px;
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-lp-flow {
    margin: 0 24px 48px;
  }

  .p-lp-flow__head {
    font-size: $fs-6xl;
    margin-bottom: 48px;
  }

  .p-lp-flow__inner {
    gap: 32px;
    flex-direction: row;
  }

  .p-lp-flow__card {
    &:first-child {
      margin-bottom: 48px;
    }

    &:nth-child(2) {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    &:nth-child(3) {
      margin-top: 48px;
    }
  }
}

/* 1300px ~ */
@include min-screen($breakpoint-pc-large-max) {
  .p-lp-flow {
    margin: 0 0 48px;
  }
}
