@mixin scrollbar-none {
  /* For IE Edge */
  -ms-overflow-style: none;
  /* For Firefox */
  scrollbar-width: none;
  /* For Chrome */
  &::-webkit-scrollbar {
    display: none;
  }
}
