@charset "utf-8";

$slide-margin-top: 40px;

.c-slide {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $slide-frame-zindex;
  background-color: rgba(102, 102, 102, 0.8);
  backface-visibility: hidden;
}

.c-slide__main {
  position: relative;
  z-index: $slide-zindex;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - #{$slide-margin-top});
  margin-top: $slide-margin-top;
  background-color: $white;
  border-radius: 12px 12px 0 0;
}

.c-slide__title {
  margin-bottom: 32px;
  font-size: $fs-5xl;
  font-weight: $fw-bold;
  color: $gray-700;
}

.c-slide__desc {
  margin-bottom: 24px;
}

.c-slide__closer {
  position: absolute;
  top: -48px;
  right: -8px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  background: url("images/icon/close-white.svg") no-repeat center;
}

.c-slide__body {
  flex: 1;
  height: 100%;
  padding-top: 64px;
  overflow-y: scroll;
  font-size: $fs-lg;
  border-bottom: 1px solid $gray-300;
  -webkit-overflow-scrolling: touch;
}

.c-slide__inner {
  max-width: 1300px;
  margin: 0 auto;
}

.c-slide__footer {
  display: flex;
  justify-content: center;
  padding: 24px;
}
