@charset "utf-8";

.p-patient__table {
  & th {
    width: 15%;

    &:nth-child(1) {
      width: 9%;
    }

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(5) {
      width: 10%;
    }

    &:last-child {
      width: 18%;
    }
  }
}

.p-patient__table__link {
  font-weight: $fw-bold;
}
