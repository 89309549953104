@charset "utf-8";

$breakpoint-pc-large-max: 1300px;
$breakpoint-pc-large-min: 1081px;

$breakpoint-pc-max: 1080px;
$breakpoint-pc-min: 900px;

$breakpoint-pc-small-max: 899px;
$breakpoint-pc-small-min: 769px;

$breakpoint-tablet-max: 768px;
$breakpoint-tablet-min: 481px;

$breakpoint-sp: 480px;
$breakpoint-sp-min: 320px;

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}
