@charset "utf-8";

.c-side-slide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $side-slide-zindex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 428px;
  background-color: $white;
}

.c-side-slide__head {
  padding: 16px 24px;
  font-size: $fs-xl;
  font-weight: $fw-bold;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.c-side-slide__main {
  height: 100%;
  overflow-y: scroll;
  @include scrollbar-none;
}

.c-side-slide__virtual {
  @include scrollbar-none;
}

.c-side-slide__bottom {
  display: flex;
  gap: 12px;
  justify-content: center;
  padding: 16px 24px;
  border-top: 1px solid $gray-300;
}
