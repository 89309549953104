@charset "utf-8";

.p-map-spot__desc {
  margin-bottom: 16px;
  font-size: $fs-sm;
}

.p-map-spot__groups {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.small {
    gap: 8px;
  }
}

.p-map-spot__bottom {
  flex-direction: column;
  align-items: center;
}

.p-map-spot__button {
  width: 300px;
  margin-bottom: 12px;
}
