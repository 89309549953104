.c-form-check__mimic {
  display: block;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-right: 8px;
  background-image: url("images/icon/checkbox-off.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.c-form-check {
  display: flex;
  align-items: center;
  font-size: $fs-2md;
  cursor: pointer;

  input[type="checkbox"] {
    display: none;
    appearance: none;
  }

  input[type="checkbox"]:checked + &__mimic {
    background-image: url("images/icon/checkbox-on.svg");
  }

  input[type="checkbox"]:indeterminate + &__mimic {
    background-image: url("images/icon/checkbox-mixed.svg");
  }

  input[type="checkbox"]:disabled + &__mimic {
    background-image: url("images/icon/checkbox-disabled.svg");
  }
}

.c-form-checkes {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &.col1 {
    @include form-grid(1);
  }

  &.col2 {
    @include form-grid(2);
  }

  &.col3 {
    @include form-grid(3);
  }

  &.col4 {
    @include form-grid(4);
  }

  &.col5 {
    @include form-grid(5);
  }
}
