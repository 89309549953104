@charset "utf-8";

.p-top-news {
  border: 1px solid $gray-400;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.p-top-news__title {
  font-weight: $fw-bold;
  font-size: $fs-xl;
}

.p-top-news__title--small {
  font-size: $fs-2md;
}

.p-top-news__text {
  font-size: $fs-2md;
  font-weight: $fw-bold;
}

.p-top-news__date {
  display: inline-block;
  margin-right: 16px;
  font-weight: normal;
}

.p-top-news__close {
  width: 12px;
  height: 12px;
  cursor: pointer;
  background: url("images/icon/close.svg") no-repeat center;
  background-size: cover;
}
