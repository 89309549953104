@charset "utf-8";

.p-facility-care__desc {
  margin-bottom: 24px;
}

.p-facility-care__head {
  margin-bottom: 16px;
}

.p-facility-care__caption {
  font-size: $fs-2md;
  font-weight: $fw-bold;
}

.p-facility-care__bulk {
  margin-bottom: 16px;
  border-top: 1px solid $gray-300;
}

.p-facility-care__items {
  border-top: 1px solid $gray-300;
}

.p-facility-care__item {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray-300;
}

.p-facility-care__title {
  font-weight: $fw-bold;
  width: 400px;
  font-size: $fs-2md;
}
