@charset "utf-8";

.p-lp-top {
  background-image: url("images/lp/top.png");
  background-size: auto 85%;
  background-repeat: no-repeat;
  color: $white;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
}

.p-lp-top__inner {
  padding: 32px 24px 8px;
}

.p-lp-top__logo {
  width: 270px;
  height: 24px;
  margin-bottom: 56px;
  background-image: url("images/lp/logo_w.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.p-lp-top__title {
  font-weight: $fw-bold;
  font-size: 28px;
  line-height: 1.7;
  margin-bottom: 12px;

  br {
    display: none;
  }
}

.p-lp-top__desc {
  margin-bottom: 16px;
  font-weight: $fw-bold;
  font-size: $fs-2md;
  line-height: 200%;

  br {
    display: none;
  }
}

.p-lp-top__cmd {
  width: 100%;
  margin: 0 auto 16px;
}

.p-lp-top__cap--pc {
  display: none;
  width: 45%;
  padding-top: 130px;

  img {
    width: 100%;
  }
}

.p-lp-top__cap--sp {
  width: calc(100% - 32px);
  margin: 0 auto;
  max-width: 450px;

  img {
    width: 100%;
  }
}

/* 768px ~ */
@include min-screen($breakpoint-tablet-max) {
  .p-lp-top__logo {
    width: 320px;
    height: 40px;
    margin-bottom: 40px;
  }

  .p-lp-top__inner {
    padding: 32px 48px 8px;
  }

  .p-lp-top__title {
    font-size: 2.3rem;
    text-align: center;

    br {
      display: block;
    }
  }

  .p-lp-top__cmd {
    width: 80%;
  }

  .p-lp-top__cap--sp {
    max-width: 700px;
  }

  .p-lp-top__desc {
    text-align: center;
    font-size: $fs-xl;
    margin-bottom: 32px;

    br {
      display: block;
    }
  }
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-lp-top__logo {
    margin-bottom: 60px;
  }

  .p-lp-top__title {
    font-size: 2.7rem;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-lp-top {
    flex-direction: row;
    background-image: url("images/lp/top.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 750px;
    margin-bottom: 100px;
  }

  .p-lp-top__logo {
    width: 440px;
    height: 60px;
    margin-bottom: 70px;
  }

  .p-lp-top__inner {
    padding: 60px 0 0 100px;
    width: 55%;
  }

  .p-lp-top__title {
    text-align: left;

    br {
      display: none;
    }
  }

  .p-lp-top__desc {
    line-height: 200%;
    text-align: left;

    br {
      display: none;
    }
  }

  .p-lp-top__cap--pc {
    display: block;
  }

  .p-lp-top__cap--sp {
    display: none;
  }

  .p-lp-top__cmd {
    margin-right: initial;
    margin-left: initial;
    max-width: 300px;
  }
}

/* 1300px ~ */
@include min-screen($breakpoint-pc-large-max) {
  .p-lp-top {
    height: 900px;
    margin-bottom: 60px;
  }

  .p-lp-top__logo {
    margin-bottom: 120px;
  }

  .p-lp-top__inner {
    padding: 60px 0 0 130px;
  }

  .p-lp-top__desc {
    br {
      display: block;
    }
  }
}

/* 1600px ~ */
@include min-screen(1600px) {
  .p-lp-top__inner {
    padding: 80px 0 0 130px;
    width: 55%;
  }

  .p-lp-top__title {
    font-size: 3.1rem;

    br {
      display: block;
    }
  }

  .p-lp-top__cap--pc {
    padding-top: 80px;
    width: 45%;
  }
}
