@charset "utf-8";

.p-map-list-select {
}

.p-map-list-select__lists {
  margin-bottom: 24px;
}

.p-map-list-select__list {
  padding: 16px 24px;
  border: 2px solid $gray-400;
  border-radius: 8px;
  display: flex;
  cursor: pointer;

  &.checked {
    border: 2px solid $green-100;
  }

  &.checked::after {
    @include icon(22px, 22px);
    content: "";
    background-image: url("images/icon/checked.svg");
    margin-left: auto;
  }

  &::after {
    @include icon(22px, 22px);
    content: "";
    background-image: url("images/icon/checked--gray.svg");
    margin-left: auto;
  }
}

.p-map-list-select__list + .p-map-list-select__list {
  margin-top: 12px;
}

.p-map-list-select__button {
  width: 240px;
  margin: 0 auto 24px;
}

.p-map-list-select__new {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.p-map-list-select__new__text {
  margin: 0 16px;
}

.p-map-list-select__line {
  flex: 1;
  border: 1px solid $gray-300;
}

.p-map-list-select__create {
  width: 240px;
  margin: 0 auto 48px;
}

.p-map-list-select__desc {
  margin-bottom: 24px;
}
