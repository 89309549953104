@charset "utf-8";

$white: #fff;
$black: #000;

$blue: #1298b3;
$blue-lighten: #14adcc;

$red: #ff5959;
$red-bright: rgba(255, 89, 89, 0.1);

$green: #009999;
$green-100: #36a684;
$green-200: #f3f8f6;
$green-300: #e2f0eb;
$green-400: #d7ede6;
$green-500: #e2f0ea;

$yellow: #f2b830;

$brown: #946e16;

$gray-800: #222222;
$gray-700: #333333;
$gray-600: #666666;
$gray-500: #888888;
$gray-400: #e0e0e0;
$gray-300: #eeeeee;
$gray-200: #f2f2f2;
$gray-100: #f7f7f7;
$gray-50: #f9f9f9;
