@charset "utf-8";

.p-facility-header {
  text-align: center;
}

.p-facility-header__title {
  margin-bottom: 16px;
  font-size: $fs-2xl;
  font-weight: $fw-bold;
}

.p-facility-header__desc {
  text-align: center;
  line-height: $lh-medium;
}

.p-facility-header__preview {
  width: 240px;
}
