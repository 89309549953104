@charset "utf-8";

.p-login-info__main {
  width: 700px;
}

.p-login-info__block {
  margin-bottom: 32px;
}

.p-login-info__h1 {
  margin-bottom: 16px;
  font-weight: $fw-bold;
  font-size: $fs-3xl;
}

.p-login-info__h2 {
  margin-bottom: 8px;
  font-weight: $fw-bold;
  font-size: $fs-xl;
}

.p-login-info__text {
  margin-bottom: 16px;
  font-size: $fs-2md;
  line-height: $lh-medium;
}

.p-login-info__img {
  width: 100%;
}
