@charset "utf-8";

.p-map-search {
  min-width: 1080px;
}

.p-map-search__head {
  font-size: $fs-5xl;
  font-weight: $fw-bold;
}

.p-map-search__group {
  margin-left: 28px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.p-map-search__group--center {
  align-items: center;
}

.p-map-search__title {
  width: 100px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.p-map-search__content {
  flex: 1;
}

.p-map-search__text {
  margin-top: 8px;
  font-size: $fs-sm;
}

.p-map-search__content__range {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;

  span {
    font-size: $fs-2md;
    font-weight: $fw-bold;
  }
}

.p-map-search__items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.p-map-search__item {
  width: calc((100% - 32px) / 5);
}
