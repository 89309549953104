@charset "utf-8";

.o-app {
  display: flex;
  flex-direction: column;
  min-width: $app-min-width;
  min-height: 100vh;
  background-color: $white;
}

.o-app__header {
  height: $header-height;
}

.o-app__main {
  flex: 1;
  width: 100%;
  max-width: $app-max-width;
  margin: 0 auto;
}

.o-app__inner {
  max-width: 1600px;
  padding: 24px 40px;
}

/* sp */
@include max-screen($breakpoint-tablet-max) {
  body.res {
    .o-app {
      min-width: $breakpoint-sp-min;
      background-color: $white;
    }
  }
}
