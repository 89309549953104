@charset "utf-8";

.o-flex {
  display: flex;
  align-items: center;
}

.o-flex-column {
  display: flex;
  flex-direction: column;
}

.o-flex--xs {
  gap: 8px;
}

.o-flex--sm {
  gap: 12px;
}

.o-flex--md {
  gap: 16px;
}

.o-flex--lg {
  gap: 24px;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}
