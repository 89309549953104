@charset "utf-8";

.p-login-ie {
  border-radius: 8px;
  border: 1px solid $red;
  background-color: $red-bright;
  padding: 16px 24px;
  margin: 32px auto;
  max-width: 600px;
  width: 80%;

  span {
    font-weight: $fw-bold;
  }

  a {
    text-decoration: underline;
  }
}
