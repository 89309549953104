@charset "utf-8";

$msg-file-size: 14px;

.p-msg-files {
  display: flex;
  gap: 12px;
  margin-left: 16px;
}

.p-msg-file {
  padding: 8px 16px;
  border: 1px solid $gray-400;
  border-radius: 6px;
  position: relative;
}

.p-msg-file__del {
  position: absolute;
  top: calc(-#{$msg-file-size} / 2);
  right: calc(-#{$msg-file-size} / 2);
  width: $msg-file-size;
  height: $msg-file-size;
  cursor: pointer;
  background: url("images/icon/close-round.svg") no-repeat center;
}
