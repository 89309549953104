@charset "utf-8";

.p-facility-status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.p-facility-status__label {
  width: 120px;
}

.p-facility-table__icon {
  width: 16px;
  height: 16px;
}
