@charset "utf-8";

.p-facility-side {
  border: 1px solid $gray-400;
  border-radius: 8px;
  padding: 24px;
}

.p-facility-side__tel {
  font-size: $fs-6xl;
  font-weight: $fw-bold;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 36px;
  margin-bottom: 8px;

  & i {
    width: 32px;
    height: 32px;
  }

  &.small {
    font-size: $fs-3xl;
  }
}

.p-facility-side__desc {
  font-size: $fs-xs;
  color: $gray-600;
  margin-bottom: 12px;
}

.p-facility-side__fax {
  font-size: $fs-2md;
  font-weight: $fw-bold;
  margin-bottom: 12px;
}

.p-facility-side__talk {
  margin-bottom: 16px;
}

.p-facility-side__keep {
  margin-bottom: 24px;
}

.p-facility-side__op {
  font-size: $fs-lg;
  font-weight: $fw-bold;
  height: 28px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;

  & i {
    width: 22px;
    height: 28px;
  }
}

.p-facility-side__text {
  margin-bottom: 12px;
  line-height: $lh-medium;
}

.p-facility-side__req {
  margin-bottom: 12px;
}

.p-facility-side__free {
  font-size: $fs-5xl;
  font-weight: $fw-bold;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 30px;
  margin-bottom: 8px;

  & i {
    width: 28px;
    height: 20px;
  }

  &.small {
    font-size: $fs-3xl;
  }
}

.p-facility-side__sm {
  font-size: $fs-xs;
  color: $gray-600;
}
