@charset "utf-8";

.p-lp-form {
  background-color: $white;
}

.p-lp-form__logo {
  display: block;
  margin: 24px;

  & img {
    width: 300px;
  }
}

.p-lp-form__h1 {
  font-weight: 600;
  font-size: 24px;
  margin-left: 24px;
}

.p-lp-form__form {
  margin: 0 24px 80px;
}

.p-lp-form__items {
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.p-lp-form__term {
  margin: 0 auto 32px;
}

.p-lp-form__term__text {
  font-size: $fs-md;
}

.p-lp-form__button {
  padding: 10px;
  width: 100%;
}

.p-lp-form__footer {
  background: $gray-50;

  a {
    background-color: $gray-50;
  }
}

.p-lp-form__block + .p-lp-form__block {
  margin-top: 16px;
}

.p-lp-form__t1 {
  margin-bottom: 30px;
}

.p-lp-form__title {
  margin-bottom: 8px;
  font-weight: $fw-bold;
  font-size: $fs-2md;
}

.p-lp-form__send {
  padding: 10px;
  width: 100%;
  margin-top: 32px;
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-lp-form__logo {
    margin: 40px 0 40px 130px;
  }

  .p-lp-form__form {
    width: 600px;
    box-shadow: 0 0 24px rgba(163, 163, 163, 0.25);
    border-radius: 16px;
    padding: 40px 75px;
    margin: 0 auto 120px;
  }

  .p-lp-form__h1 {
    margin: 0 0 32px;
    text-align: center;
  }

  .p-lp-form__t1 {
    text-align: center;
  }

  .p-lp-form__term__text {
    font-size: $fs-2md;
  }
}
