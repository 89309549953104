@charset "utf-8";

.p-patient-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: $green-200;
  border: 1px solid $green-100;
  border-radius: 8px;
  margin-bottom: 20px;
}

.p-patient-notificatoin__title {
  font-weight: $fw-bold;
  font-size: $fs-xl;
  line-height: $lh-large;
}
