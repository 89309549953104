@charset "utf-8";

.o-column {
  display: flex;
  min-height: calc(100vh - #{$header-height});
}

.o-column__main {
  flex: 1;
  padding: 24px;
}
