@charset "utf-8";

.p-top-head {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.p-top-head__title {
  margin-right: 12px;
  margin-bottom: 0;
}

.p-top-head__drop {
  min-width: 220px;
}

.p-top-head__drop__toggle {
  &.select {
    border-radius: 32px;
    padding: 7px 32px 7px 16px;
  }
}

.p-top-head__drop__menu {
  min-width: 180px;
}

.p-top-head__right {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  font-size: $fs-2md;
  font-weight: $fw-bold;
}
