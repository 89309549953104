@charset "utf-8";

.p-facility-detail {
  display: flex;
  gap: 24px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px 24px;
}

.p-facility-detail__main {
  flex: 1;
  width: calc(100% - 360px);
}

.p-facility-detail__side {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 360px;
}
