@charset "utf-8";

.p-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.p-error__inner {
  width: 1080px;
  padding: 32px 0;
  text-align: center;
}

.p-error__image {
  width: 400px;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.p-error__title {
  font-size: $fs-4xl;
  font-weight: bold;
  color: $gray-700;
  margin-bottom: 16px;
}

.p-error__text {
  font-size: $fs-lg;
  color: $gray-700;
}

.p-error__button {
  margin: 40px auto 0;
  width: 300px;
}
