@charset "utf-8";

.p-login-form {
  width: 450px;
  margin: 0 auto;

  & .c-form-group__label {
    font-size: $fs-md;
    color: $gray-500;
    margin-bottom: 8px;
  }
}

.p-login-form__link {
  font-weight: $fw-normal;
}

.p-login-form__submit {
  margin-top: 24px;
  width: 100%;
}

.p-login-form__title {
  font-size: $fs-2xl;
  font-weight: $fw-bold;
  text-align: center;
  margin-bottom: 24px;
}

/* sp */
@include max-screen($breakpoint-tablet-max) {
  .p-login-form {
    width: 100%;
  }
}
