@charset "utf-8";

.p-map-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 48px 24px;
  text-align: center;
  height: 100%;
}

.p-map-empty__title {
  margin-bottom: 8px;
  font-size: $fs-2xl;
  font-weight: $fw-bold;
}

.p-map-empty__desc {
  font-weight: $fw-bold;
  color: $gray-500;
  line-height: $lh-medium;
}
