@charset "utf-8";

.p-top-vacancys {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-bottom: 32px;
}

.p-top-vacancy {
  padding: 16px 24px;
  border: 1px solid $gray-400;
  border-radius: 12px;
}

.p-top-vacancy__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.p-top-vacancy__head {
  margin-bottom: 4px;
  font-size: $fs-xl;
  font-weight: $fw-bold;
}

.p-top-vacancy__address {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: $gray-600;
  font-size: $fs-sm;
}

.p-top-vacancy__room {
  margin-right: 12px;
  font-weight: $fw-bold;
  background-color: $green-300;
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.p-top-vacancy__desc {
  color: $gray-600;
  font-size: $fs-xs;
}

.p-top-vacancy__row {
  display: flex;
}

.p-top-vacancy__title {
  margin-right: 8px;
  font-weight: $fw-bold;
}

.p-top-vacancy__content {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  flex: 1;
}

.p-top-vacancy__cmd {
  width: 220px;
  margin: 0 auto;
}
