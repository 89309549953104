@charset "utf-8";

.c-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-size: $fs-sm;
  font-weight: $fw-bold;
  text-align: center;
  word-break: keep-all;
  border-radius: 4px;
  width: fit-content;
}

@include label("c-label--gray100", $gray-100, $gray-700);

@include label("c-label--gray200", $gray-200, $gray-700);

@include label("c-label--gray300", $gray-300, $gray-700);

@include label("c-label--gray700", $gray-700, $white);

@include label("c-label--green100", $green-100, $white);

@include label("c-label--green50", rgba(54, 166, 132, 0.2), $gray-700);

@include label("c-label--green300", $green-300, $gray-700);

@include label("c-label--green500", $green-500, $gray-700);

@include label("c-label--yellow", $yellow, $gray-700);

.c-label--round {
  padding: 4px 16px;
  border-radius: 32px;
}

.c-label--large {
  font-size: $fs-md;
}

.c-label--small {
  padding: 2px 6px;
}

.c-label--solid {
  padding: 10px 16px;
}

.c-label__icon {
  margin-right: 8px;
}
