@charset "utf-8";

.c-list {
  border: 1px solid $gray-400;
  border-radius: 4px;
}

.c-list__item {
  padding: 16px;
}

.c-list__item + .c-list__item {
  border-top: 1px solid $gray-400;
}
