@charset "utf-8";

.p-map-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}

.p-map-info__head {
  display: inline-block;
  margin-bottom: 12px;
  margin-right: 12px;
}

.p-map-info__head__inner {
  font-weight: $fw-bold;
  background-color: $green-300;
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.p-map-info__date {
  color: $gray-500;
  display: inline-block;
  font-size: $fs-sm;
}

.p-map-info__main {
  font-size: $fs-sm;
}

.p-map-info__detail {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  p {
    font-weight: $fw-bold;
  }
}

.p-map-info__desc {
  font-size: $fs-xs;
  color: $gray-500;
}
