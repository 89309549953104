@charset "utf-8";

.c-tel__desc {
  margin-bottom: 16px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.c-tel__main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-tel__num {
  margin-right: 12px;
  font-size: $fs-5xl;
  font-weight: $fw-700;
}

.c-tel__time {
  font-size: $fs-2md;
}

.c-tel__icon {
  @include icon(24px, 24px);
  margin-right: 4px;
  background-image: url("images/icon/tel-right.svg");
}
