@charset "utf-8";

.p-lp-features {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.p-lp-feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  &:nth-child(even) {
    flex-direction: column-reverse;
  }
}

.p-lp-feature__main {
  width: calc(100% - 48px);
  padding: 32px 0 16px;
  border-top: 1px solid $black;
}

.p-lp-feature__top {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
}

.p-lp-feature__t1 {
  font-weight: 700;
  font-size: $fs-2md;
  color: $green-100;
}

.p-lp-feature__num {
  font-weight: 100;
  font-size: 64px;
  line-height: 1;
}

.p-lp-feature__title {
  font-weight: $fw-bold;
  font-size: $fs-3xl;
  line-height: $lh-large;

  br {
    display: none;
  }
}

.p-lp-feature__desc {
  font-size: $fs-md;
  line-height: 200%;
}

.p-lp-feature__img {
  width: calc(100% - 80px);
  margin: 0 auto;

  img {
    width: 100%;
  }
}

/* 768pxpx ~ */
@include min-screen($breakpoint-tablet-max) {
  .p-lp-features {
    gap: 80px;
  }

  .p-lp-feature__img {
    width: calc(100% - 240px);
    margin: 0 auto;

    img {
      width: 100%;
    }
  }
}

/* 899px ~ */
@include min-screen($breakpoint-pc-small-max) {
  .p-lp-feature {
    margin: 0 24px 32px;
    flex-direction: row;

    &:nth-child(even) {
      flex-direction: row;
    }
  }

  .p-lp-feature__main {
    width: 500px;
    padding: 40px 0;
    border-bottom: 1px solid $black;
    transform: scale(0.85);
  }

  .p-lp-feature__t1 {
    font-size: $fs-2xl;
  }

  .p-lp-feature__num {
    font-size: 110px;
  }

  .p-lp-feature__title {
    font-size: 30px;

    br {
      display: block;
    }
  }

  .p-lp-feature__desc {
    font-size: $fs-lg;
  }

  .p-lp-feature__img {
    width: calc(100% - 560px);
    margin: 0;
  }
}

/* 1300px ~ */
@include min-screen($breakpoint-pc-large-max) {
  .p-lp-feature {
    margin: 0;
  }

  .p-lp-feature__main {
    transform: scale(1);
  }
}
