.c-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(204, 204, 204, 0.6);
}

.c-header__main {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 16px 16px 16px 40px;
}

.c-header__logo {
  display: flex;
  align-items: center;

  & img {
    width: 230px;
  }
}

.c-header__name {
  font-weight: $fw-bold;
  transform: scale(0.8);
}

.c-header__navs {
  display: flex;
  gap: 8px;
  margin-left: auto;
}

.c-header__nav {
  font-size: $fs-xs;
  font-weight: $fw-bold;
  color: $gray-700;
}

%c-header__icon {
  display: block;
  margin: 0 auto 4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.c-header__patient {
  @extend %c-header__icon;
  width: 21px;
  height: 25px;
  background-image: url("images/icon/patient.svg");
}

.c-header__search {
  @extend %c-header__icon;
  width: 22px;
  height: 25px;
  background-image: url("images/icon/search.svg");
}

.c-header__request {
  @extend %c-header__icon;
  width: 21px;
  height: 24px;
  background-image: url("images/icon/request.svg");
}

.c-header__docs {
  @extend %c-header__icon;
  width: 31px;
  height: 24px;
  background-image: url("images/icon/docs.svg");
}

.c-header__offer {
  @extend %c-header__icon;
  width: 24px;
  height: 24px;
  background-image: url("images/icon/offer.svg");
}

.c-header__bed {
  @extend %c-header__icon;
  width: 24px;
  height: 24px;
  background-image: url("images/icon/bed-black.svg");
}

.c-header__nav.current {
  color: $green-100;

  & .c-header__docs {
    background-image: url("images/icon/docs-green.svg");
  }

  & .c-header__patient {
    background-image: url("images/icon/patient-green.svg");
  }

  & .c-header__offer {
    background-image: url("images/icon/offer-green.svg");
  }
}

.c-header__nav.badge {
  position: relative;

  &:after {
    position: absolute;
    top: -1px;
    right: -2px;
    display: block;
    width: 8px;
    height: 8px;
    content: "";
    background-color: $red;
    border-radius: 50%;
  }
}

.c-header__right {
  width: 64px;
  height: 100%;
  border-left: 1px solid rgba(204, 204, 204, 0.6);
}

.c-header__drop {
  display: block;
  height: 100%;
  background-image: url("images/icon/setting.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 16px;
}

.c-header__menus {
  width: 240px;
}

/* 1300px ~ */
@include min-screen($breakpoint-pc-large-max) {
  .c-header__main {
    padding: 16px 32px 16px 40px;
  }

  .c-header__logo {
    & img {
      width: 280px;
    }
  }

  .c-header__navs {
    gap: 24px;
  }

  .c-header__nav {
    font-size: $fs-md;
    transform: scale(1);
  }

  .c-header__name {
    margin-left: 24px;
    transform: scale(1);
  }

  .c-header__right {
    width: 72px;
  }
}
