@charset "utf-8";

.p-msg-footer {
  padding: 16px 24px;

  & .c-form-group__label {
    font-size: $fs-md;
    font-weight: $fw-bold;
    min-width: 100px;
  }
}

.p-msg-footer__msg {
  position: relative;
}

.p-msg-footer__send {
  position: absolute;
  right: 16px;
  top: 12px;
  width: 34px;
  height: 34px;
  background-image: url("images/icon/send-talk.svg");
}

.p-msg-footer__input {
  padding: 12px 54px 12px 16px;
  background-color: $gray-200;
  border: none;
}
