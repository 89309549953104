@charset "utf-8";

.c-table {
  width: 100%;
  font-size: $fs-2md;

  & th,
  td {
    padding: 16px 8px;
    text-align: left;
    vertical-align: middle;
  }

  & th {
    font-size: $fs-lg;
    font-weight: $fw-bold;
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;

    &:first-child {
      padding-left: 8px;
    }
  }

  & .sort-desc::after {
    @include icon(10px, 10px);
    margin-left: 8px;
    content: "";
    background-image: url("images/icon/sort_desc.svg");
  }

  & td {
    border-bottom: 1px solid $gray-300;

    &:first-child {
      padding-left: 8px;
    }
  }
}
