@charset "utf-8";

%link {
  padding: 0;
  color: $gray-700;
  text-decoration: none;
  appearance: none;
  background-color: $white;
  border: none;
  outline: none;

  &:visited {
    color: $gray-700;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.6;
    transition: 0.2s;
  }

  &:active {
    color: $gray-700;
  }
}

a {
  @extend %link;
}
