@charset "utf-8";

.p-lp__cmd {
  background-color: $white;
  padding: 12px;
  font-size: $fs-lg;
  color: $green-100;

  &:after {
    display: block;
    margin-left: 12px;
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: "";
    background-image: url("images/icon/arrow-right-green.svg");
  }

  &:visited {
    color: $green-100;
  }

  &:active {
    color: $green-100;
  }
}
