@charset "utf-8";

.p-msg-panel {
  flex: 1;
  border-top: 1px solid $gray-400;

  &:first-child {
    border-top: none;
  }
}

.p-msg-panel__head {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid $gray-400;
  font-size: $fs-xl;
  font-weight: $fw-bold;
}

.p-msg-panel__msg-icon {
  @include icon(26px, 18px);
  background-image: url("images/icon/message-green.svg");
  margin-right: 12px;
}

.p-msg-panel__patient-icon {
  @include icon(20px, 20px);
  background-image: url("images/icon/patient-info.svg");
  margin-right: 12px;
}

.p-msg-panel__main {
  max-height: calc((100vh - #{$header-height}) / 2 - 60px);
  overflow-y: scroll;
}

.p-msg-panel__time {
  margin-left: auto;
  font-size: $fs-xs;
  color: $gray-500;
}
