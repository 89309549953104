@charset "utf-8";

.p-facility-tag + .p-facility-tag {
  margin-top: 16px;
}

.p-facility-tag__title {
  font-size: $fs-2md;
  font-weight: $fw-bold;
  margin-bottom: 8px;
}
