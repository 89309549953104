@charset "utf-8";

.p-map-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid $gray-300;
  font-size: $fs-lg;
  font-weight: $fw-bold;
  cursor: pointer;

  &::after {
    display: block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: "";
    background-image: url("images/icon/arrow-right-green.svg");
  }
}

.p-map-list__button {
  width: 160px;
}

.p-map-list__info {
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  font-weight: $fw-normal;
}
