@charset "utf-8";

.p-map-list-modal__desc {
  margin-bottom: 12px;
  font-size: $fs-md;
}

.p-map-list-modal__button {
  width: 300px;
  margin: 32px auto 40px;
}
