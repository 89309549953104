@charset "utf-8";

.p-facility-medical {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border: 1px solid $gray-300;
  margin-bottom: 12px;
}

.p-facility-medical__item {
  display: flex;
  border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
}

.p-facility-medical__marker {
  padding: 10px;
  width: 36px;
  background-color: $gray-50;
}

.p-facility-medical__accept {
  @include icon(16px, 16px);
  background-image: url("images/icon/circle.svg");
}

.p-facility-medical__res {
  @include icon(16px, 16px);
  background-image: url("images/icon/triangle.svg");
}

.p-facility-medical__refuse {
  @include icon(16px, 16px);
  background-image: url("images/icon/cross.svg");
}

.p-facility-medical__text {
  padding: 10px;
  font-weight: $fw-bold;
}
