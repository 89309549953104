@charset "utf-8";

.p-map-cards {
  width: 500px;
  height: calc(100vh - #{$map-header-height} - #{$header-height});
  overflow-y: scroll;
  @include scrollbar-none;
}

.p-map-cards__head {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.p-map-cards__count {
  font-weight: $fw-bold;
}

.p-map-cards__virtuoso {
  height: 100%;
  @include scrollbar-none;
}

.p-map-card {
  padding: 24px;
  border-bottom: 1px solid $gray-300;
  position: relative;
}

.p-map-card__dropdown {
  position: absolute;
  right: 24px;
}

.p-map-card__menu {
  width: 200px;
  right: -20px;
}

.p-map-card__name {
  margin-bottom: 8px;
  font-size: $fs-2xl;
  font-weight: $fw-bold;
}

.p-map-card__address {
  margin-bottom: 12px;
  color: $gray-600;
}

.p-map-card__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: calc(100% - 24px);
  margin-bottom: 8px;
}

.p-map-card__tel {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  font-size: $fs-5xl;
  font-weight: $fw-bold;
  color: $green-100;
}

.p-map-card__fax {
  font-size: $fs-2md;
  font-weight: $fw-bold;
  margin-bottom: 8px;
}

.p-map-card__footer {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
}

.p-map-card__btn {
  width: 210px;
}

.p-map-card__cost {
  font-weight: $fw-bold;
  margin-bottom: 4px;

  & > .cost {
    color: $green-100;
  }
}

.p-map-card__desc {
  font-size: $fs-sm;
  color: $gray-600;
}

.p-map-cards__empty {
  padding: 24px;
}

.p-map-cards__empty__head {
  font-size: $fs-xl;
  font-weight: $fw-bold;
}

.p-map-cards__empty__text {
  line-height: $lh-medium;
}

.p-map-cards__empty__h2 {
  margin-bottom: 4px;
  font-size: $fs-2md;
  font-weight: $fw-bold;
}

.p-map-cards__empty__button {
  width: 240px;
}
