@charset "utf-8";

.p-patient-care__row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 16px;
}

.p-patient-care__de {
  display: flex;
  align-items: center;
  gap: 8px;
}
