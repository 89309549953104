@charset "utf-8";

.c-link {
  @extend %link;
}

.c-link--flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.c-link--green {
  color: $green-100;
  font-weight: $fw-bold;

  &:visited {
    color: $green-100;
  }

  &:active {
    color: $green-100;
  }
}

.c-link--bold {
  font-weight: $fw-bold;
}
