@charset "utf-8";

.p-top-alert {
  border: 1px solid $yellow;
  background-color: rgba($yellow, 0.1);
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 24px;

  a {
    background-color: transparent;
  }
}
