@charset "utf-8";

.c-more {
  position: relative;
  height: 30px;
  cursor: pointer;
}

.c-more__inner {
  position: relative;
  padding-bottom: 10px;
  text-align: center;
}

.c-more__inner:before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 90px;
  content: "";
  background: linear-gradient(
    360deg,
    #ffffff 43.53%,
    rgba(255, 255, 255, 0.67152) 74.48%,
    rgba(255, 255, 255, 0.0001) 100%
  );
}

.c-more__link {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: $fw-bold;
}

.c-more:hover .c-more__link {
  opacity: 0.5;
}
