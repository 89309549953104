@charset "utf-8";

.p-map-slide__card {
  padding: 16px 24px;
  font-size: $fs-sm;
  border-bottom: 1px solid $gray-300;
}

.p-map-slide__head {
  font-size: $fs-lg;
  font-weight: $fw-bold;
  margin-bottom: 8px;
}

.p-map-slide__item + .p-map-slide__item {
  margin-top: 4px;
}

.p-map-slide__title {
  margin-bottom: 4px;
  font-weight: $fw-bold;
}

.p-map-slide__card__actions {
  display: flex;
  justify-content: flex-end;
}

.p-map-slide__del {
  font-size: $fs-xs;
  color: $gray-500;
}

.p-map-slide__keep {
  display: flex;
  justify-content: center;
}

.p-map-slide__button {
  width: 180px;
}
