@charset "utf-8";

.c-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 18px;
  height: 18px;
  padding: 9px 5px;
  font-size: $fs-xs;
  font-weight: $fw-bold;
  color: $white;
  background-color: $red;
  border-radius: 12px;

  &.red {
    background-color: $red;
    border: 2px solid $white;
  }

  &.green {
    background-color: $green-100;
    border: 3px solid $white;
    border-radius: 32px;
  }
}
