@charset "utf-8";

.p-patient-detail {
  width: 65%;
  margin-right: 24px;
}

.p-patient-detail__head {
  display: flex;
  justify-content: space-between;
}

.p-patient-detail__edit {
  display: flex;
  align-items: center;
  font-size: $fs-md;

  &:before {
    @include icon(17px, 17px);
    content: "";
    margin-right: 8px;
    background-image: url("images/icon/edit.svg");
  }
}
