@charset "utf-8";

.c-icon-info {
  @include icon();
  background-image: url("images/icon/info.svg");
}

.c-icon-print {
  @include icon();
  background-image: url("images/icon/print.svg");
}

.c-icon-edit {
  @include icon();
  background-image: url("images/icon/edit.svg");
}

.c-icon-message {
  @include icon();
  background-image: url("images/icon/message.svg");
}

.c-icon-post {
  @include icon();
  background-image: url("images/icon/post.svg");
}

.c-icon-mark {
  @include icon();
  background-image: url("images/icon/mark.svg");
}

.c-icon-tel {
  @include icon();
  background-image: url("images/icon/tel.svg");
}

.c-icon-request-green {
  @include icon();
  background-image: url("images/icon/request-green.svg");
}

.c-icon-bed-green {
  @include icon();
  background-image: url("images/icon/bed-green.svg");
}

.c-icon-social {
  @include icon();
  background-image: url("images/icon/social.svg");
}

.c-icon-free-dial {
  @include icon();
  background-image: url("images/icon/free-dial.svg");
}

.c-icon-post-green {
  @include icon();
  background-image: url("images/icon/post-green.svg");
}

.c-icon-pin {
  @include icon();
  background-image: url("images/icon/pin.svg");
}

.c-icon-star {
  @include icon();
  background-image: url("images/icon/star.svg");
}

.c-icon-star-green {
  @include icon();
  background-image: url("images/icon/star-green.svg");
}

.c-icon-up-down {
  @include icon();
  background-image: url("images/icon/up_down.svg");
}

.c-icon-star-gray {
  @include icon();
  background-image: url("images/icon/star-gray.svg");
}

.c-icon-patient {
  @include icon();
  background-image: url("images/icon/patient.svg");
}

.c-icon-cry {
  @include icon();
  background-image: url("images/icon/cry.svg");
}

.c-icon-bell-green {
  @include icon();
  background-image: url("images/icon/bell-green.svg");
}

.c-icon-arrow-left {
  @include icon();
  background-image: url("images/icon/arrow-left.svg");
}

.c-icon-arrow-down-green {
  @include icon();
  background-image: url("images/icon/arrow-down-green.svg");
}

.c-icon-arrow-right-green {
  @include icon();
  background-image: url("images/icon/arrow-right-green.svg");
}

.c-icon-map-pin-green {
  @include icon();
  background-image: url("images/icon/map-pin-green.svg");
}

.c-icon--2md {
  @include icon(20px, 20px);
}

.c-icon--lg {
  @include icon(24px, 24px);
}

.c-icon--badge {
  position: relative;

  &:after {
    position: absolute;
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $red;
    top: -1px;
    right: -2px;
  }
}
