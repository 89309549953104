@charset "utf-8";

.p-login-contact {
  background-color: $gray-200;
  padding: 16px;
  border-radius: 12px;
  margin: 32px auto;
  max-width: 450px;
  width: 80%;
  text-align: center;
}

.p-login-contact__tel {
  font-size: $fs-2xl;
  justify-content: center;
}

.p-login-contact__text {
  text-align: left;
}

.p-login-contact__time {
  font-size: $fs-sm;
  margin-left: 8px;
}

/* sp */
@include max-screen($breakpoint-tablet-max) {
  .p-login-contact {
    width: auto;
    margin: 32px 0 8px;
  }

  .p-login-contact__tel {
    flex-wrap: wrap;
  }
}
