.c-form {
  width: calc(100% - 80px);
  max-width: 1080px;
  margin: 0 auto;
}

.c-form__main {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0;
  border-bottom: 1px solid $gray-300;

  &.sm {
    gap: 24px;
  }
}

.c-form__desc {
  line-height: $lh-medium;
  text-align: center;
}

.c-form__footer {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin: 24px 0;
}

.c-form__submit {
  width: 300px;
  padding: 10px 32px;
  font-size: $fs-lg;
}

.c-form__notice {
  padding: 12px 16px;
  background-color: $green-200;
  border: 1px solid $green-100;
  border-radius: 8px;
}
