@charset "utf-8";

$step-size: 16px;

.c-step {
  display: flex;
  width: 400px;
  margin: 0 auto;
}

.c-step__item {
  position: relative;
  flex: 1;
  font-weight: $fw-bold;
  color: $gray-400;
  text-align: center;

  &:before {
    display: block;
    width: $step-size;
    height: $step-size;
    margin: 0 auto 8px;
    content: "";
    background-color: $green-400;
    border-radius: 50%;
  }

  &:not(:first-child):after {
    position: absolute;
    top: 6px;
    left: calc(-50% + (#{$step-size} / 2));
    width: calc(100% - #{$step-size});
    height: 3px;
    content: "";
    background-color: $green-400;
  }

  &.active {
    color: $gray-700;

    &:before {
      background-color: $green-100;
    }

    &:not(:first-child):after {
      background-color: $green-100;
    }
  }
}
