@charset "utf-8";

.p-lp-contact {
  padding: 24px;
  margin: 24px 24px 56px;
  background: linear-gradient(116.33deg, #36a684 0%, #36a6a6 100%);
  border-radius: 24px;
  color: $white;
  font-weight: 600;
}

.p-lp-contact__left {
  border-bottom: 2px solid $white;
  text-align: center;
  padding-bottom: 16px;
}

.p-lp-contact__t1 {
  font-size: $fs-2xl;
}

.p-lp-contact__tel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-arial;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 4px;
  color: $white;

  &:before {
    display: block;
    margin-right: 8px;
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: "";
    background-image: url("images/lp/tel.svg");
  }
}

.p-lp-contact__t2 {
  font-size: $fs-md;
  margin-bottom: 8px;
}

.p-lp-contact__t3 {
  font-size: $fs-xs;
  color: rgba(255, 255, 255, 0.7);
}

.p-lp-contact__right {
  padding-top: 16px;
}

.p-lp-contact__t4 {
  margin-bottom: 12px;
  font-size: $fs-xl;
  text-align: center;
}

.p-lp-contact__cmd {
  background-color: $white;
  padding: 16px;
  font-size: $fs-2xl;
  border-radius: 12px;
  color: $green-100;

  &:after {
    display: block;
    margin-left: 12px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: "";
    background-image: url("images/icon/arrow-right-green.svg");
  }

  &:visited {
    color: $green-100;
  }
}

/* 1080px ~ */
@include min-screen($breakpoint-pc-max) {
  .p-lp-contact {
    padding: 40px 64px;
    margin: 56px 24px 120px;
    display: flex;
  }

  .p-lp-contact__left {
    border-right: 2px solid $white;
    border-bottom: 0;
    flex: 1;
    text-align: left;
    padding-bottom: 0;
  }

  .p-lp-contact__right {
    flex: 1;
    padding-left: 48px;
    padding-top: 0;
  }

  .p-lp-contact__t1 {
    font-size: $fs-4xl;
    line-height: 36px;
    margin-bottom: 12px;
  }

  .p-lp-contact__tel {
    font-size: 56px;
    margin-bottom: 4px;
    height: 64px;
    justify-content: flex-start;

    &:before {
      width: 42px;
      height: 38px;
    }
  }

  .p-lp-contact__t2 {
    font-size: $fs-xl;
  }

  .p-lp-contact__t3 {
    font-size: $fs-sm;
  }

  .p-lp-contact__t4 {
    text-align: left;
    font-size: $fs-4xl;
    margin-bottom: 24px;
  }

  .p-lp-contact__cmd {
    padding: 24px;
    font-size: $fs-3xl;
  }
}

/* 1300px ~ */
@include min-screen($breakpoint-pc-large-max) {
  .p-lp-contact {
    margin: 56px 0 120px;
  }
}
