@charset "utf-8";

.p-facility-vac {
  display: flex;
  align-items: center;
  gap: 16px;
}

.p-facility-vac + .p-facility-vac {
  margin-top: 12px;
}

.p-facility-vac__room {
  font-size: $fs-2md;
  width: 90px;
  font-weight: $fw-bold;
}

.p-facility-vac__status {
  width: auto;
}
