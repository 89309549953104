.c-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  & h1,
  h2,
  h3 {
    margin-bottom: 0;
  }
}
