@charset "utf-8";

.p-scout-top__desc {
  margin-bottom: 16px;
}

.p-scout-top__status {
  margin-bottom: 24px;
  gap: 16px;
}
