@charset "utf-8";

.c-dropdown {
  position: relative;
}

.c-dropdown__icon {
  @include icon(16px, 16px);
  cursor: pointer;
  background-image: url("images/icon/tools.svg");
}

.c-dropdown__menus {
  position: absolute;
  z-index: $dropdown-menu-zindex;
  padding: 0 24px;
  background: $white;
  border-radius: 16px;
  box-shadow: 0 0 24px rgba(141, 141, 141, 0.25);
}

.c-dropdown__menu {
  display: block;
  padding: 16px 0;
  cursor: pointer;
  border-bottom: 1px solid $gray-300;

  &:last-child {
    border-bottom: none;
  }
}

.c-dropdown__select {
  display: flex;
  align-items: center;
  padding: 4px 32px 4px 16px;
  font-size: $fs-2md;
  font-weight: $fw-bold;
  cursor: pointer;
  background-color: $white;
  background-image: url("images/icon/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) 50%;
  background-size: 10px 10px;
  border: $gray-400 solid 1px;
  border-radius: 32px;
}
