@charset "utf-8";

.c-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal-frame-zindex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($black, 0.4);
  backface-visibility: hidden;
}

.c-modal--over {
  z-index: $modal-over2-zindex;
}

.c-modal__main {
  position: relative;
  z-index: $modal-zindex;
  display: flex;
  flex-direction: column;
  width: auto;
  max-height: calc(100vh - 64px);
  margin: 0 12px;
  background-color: $white;
  border-radius: 12px;
  box-shadow: $shadow-size-medium;
}

.c-modal--sm {
  width: 600px;
}

.c-modal__head {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px 40px 0;
  margin-bottom: 16px;
}

.c-modal__title {
  display: flex;
  font-size: $fs-2xl;
  font-weight: $fw-bold;
  color: $gray-700;
}

.c-modal__closer {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url("images/icon/close.svg") no-repeat center;
  background-size: cover;
}

.c-modal__body {
  flex: 1;
  height: 100%;
  padding: 0 40px;
  overflow-y: scroll;
  font-size: $fs-lg;
  -webkit-overflow-scrolling: touch;
  @include scrollbar-none;
}

.c-modal__footer {
  display: flex;
  justify-content: center;
  padding: 32px 40px 48px;
}

.c-modal__button {
  width: 300px;
}

.c-modal__form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .c-form-group {
    align-items: center;
  }
}

/* sp */
@include max-screen($breakpoint-tablet-max) {
  body.res {
    & .c-modal__body {
      padding: 0 24px;
    }
  }
}
