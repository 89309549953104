@charset "utf-8";

.c-panel {
  border: 1px solid $gray-400;
  border-radius: 4px;
}

.c-panel__head {
  padding: 10px 16px;
  margin-bottom: 0;
  background: $gray-100;
  border-bottom: 1px solid $gray-400;
  border-radius: 4px 4px 0 0;
}

.c-panel__group {
  display: flex;
  padding: 10px 16px;
  border-bottom: 1px solid $gray-400;

  &:last-child {
    border-bottom: none;
  }
}

.c-panel__title {
  width: 150px;
  font-weight: $fw-bold;
}

.c-panel__content {
  flex: 1;
}

.c-panel__name {
  font-weight: $fw-bold;
}

.c-panel.col2 {
  & .c-panel__main {
    display: flex;
    flex-wrap: wrap;
  }

  & .c-panel__group {
    width: 50%;
  }
}

.c-panel.last2-border-none {
  & .c-panel__group:nth-last-child(2) {
    border-bottom: none;
  }
}
