.c-form-line {
  display: flex;
  gap: 16px;
}

.c-form-line__item {
  display: flex;
  flex: 1;
  align-items: center;
}

.c-form-line__item.vertical {
  flex-direction: column;
  align-items: flex-start;

  & .c-form-group__label {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.c-form-line + .c-form-line {
  margin-top: 16px;
}
