@charset "utf-8";

.p-facility-print {
  width: 210mm;
  margin: 0 auto;
}

@media print {
  @page {
    size: A4;
    margin: 10mm;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .o-app__header {
    display: none;
  }
}
