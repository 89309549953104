@charset "utf-8";

.p-patient-check__num {
  align-items: center;

  & .c-form-group__label {
    min-width: auto;
    font-size: $fs-md;
  }

  & .c-form-group__main {
    max-width: 200px;
  }
}

.p-patient-check__detail {
  display: flex;
  align-items: center;
  gap: 8px;
}

.p-patient-check__main {
  margin-top: 4px;
}

.p-patient-check__sub {
  & .c-form-group__label {
    min-width: auto;
    font-size: $fs-md;
  }
}

.p-patient-check__sub + .p-patient-check__sub {
  margin-top: 8px;
}

.p-patient-check__count {
}

.p-patient-check__text {
  width: 100%;
}
