@charset "utf-8";

.p-map-map__main {
  height: calc(100vh - #{$map-header-height} - #{$header-height});
  width: 100%;
}

.p-map-map__window {
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: $white;
  font-size: $fs-2md;
}

.p-map-map__desc {
  font-size: $fs-md;
  color: $gray-600;
}

.p-map-map__name {
  font-size: $fs-2md;
  font-weight: $fw-bold;
}
