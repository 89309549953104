@charset "utf-8";

.c-facility-print {
  position: fixed; // 非表示へする display noneはgoogle map renderされないため
  top: 0;
  left: -2000px;
  page-break-after: always;
}

.c-facility-print__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.c-facility-print__head__title {
  font-size: $fs-5xl;
  font-weight: $fw-bold;
}

.c-facility-print__top {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
  font-size: $fs-lg;
}

.c-facility-print__top__title {
  max-width: 180px;
  font-weight: $fw-bold;
}

.c-facility-print__map {
  width: 100%;
  height: 400px;
  margin-bottom: 24px;
}

.c-facility-print__caption {
  margin-bottom: 12px;
  font-size: $fs-xl;
  font-weight: $fw-bold;
}

.c-facility-print__table {
  width: 100%;
  margin-bottom: 16px;
  font-size: $fs-2md;
  border-top: 1px solid $gray-400;
  border-left: 1px solid $gray-400;

  & tr {
    border-bottom: 1px solid $gray-400;
  }

  & th {
    padding: 10px;
    background-color: $green-500;
    border-right: 1px solid $gray-400;
  }

  & td {
    padding: 10px;
    background-color: $white;
    border-right: 1px solid $gray-400;
  }
}

.c-facility-print__dial {
  @include icon(41px, 30px);
  margin-right: 8px;
  background-image: url("images/icon/free-dial.svg");
}

.c-facility-print__bottom__row {
  display: flex;
  align-items: center;
}

.c-facility-print__bottom__title {
  margin-right: 24px;
  margin-bottom: 8px;
  font-size: $fs-lg;
  font-weight: $fw-bold;
}

.c-facility-print__bottom__tel {
  margin-right: 8px;
  font-size: $fs-5xl;
  font-weight: $fw-700;
}

.c-facility-print__bottom__desc {
  color: $gray-500;
}

.c-facility-print__logo {
  width: 239px;
  height: 23px;
  margin-bottom: 32px;
  margin-left: auto;
  background-image: url("images/logo_black.png");
  background-repeat: no-repeat;
  background-size: contain;
}

@media print {
  @page {
    size: A4;
    margin: 10mm;
  }

  .c-facility-print {
    position: static;
  }
}
