@charset "utf-8";

.p-map-tool {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 12px;
}

%p-map-tool__tool {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: $fs-sm;
  font-weight: $fw-bold;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    margin-bottom: 4px;
  }
}

.p-map-tool__keep {
  @extend %p-map-tool__tool;
}

.p-map-tool__icon {
  margin-bottom: 4px;
}

.p-map-tool__list {
  @extend %p-map-tool__tool;

  &::before {
    @include icon(24px, 24px);
    background-image: url("images/icon/list.svg");
  }
}

.p-map-tool__spot {
  @extend %p-map-tool__tool;

  &::before {
    @include icon(24px, 24px);
    background-image: url("images/icon/spot.svg");
  }
}

.p-map-tool__badge {
  position: absolute;
  right: -3px;
  top: -4px;
}
