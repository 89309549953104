@charset "utf-8";

.p-patient-form__name {
  width: 32px;
}

.p-patient-form__init {
  width: calc(50% - 8px);
}

.p-patient-form__care {
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  @for $i from 5 through 9 {
    & .c-form-radio:nth-child(#{$i}) {
      grid-row-start: 2;
    }
  }

  @for $i from 10 through 11 {
    & .c-form-radio:nth-child(#{$i}) {
      grid-row-start: 3;
    }
  }
}
